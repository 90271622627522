.NavUlFlex {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 50%;
  margin-top: 30vh;
}

.growwHeight {
  min-height: 35vh !important;
}