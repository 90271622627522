.bgOverlay{
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.bgOverlay::before{
    content: '';
    height: 100%;
    width:100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: -1;
}
.bgImage{
    filter: blur(0.2rem);
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit:contain;
inset:0;
top:0;
    z-index: -2;
    background-repeat: repeat;
    overflow: hidden;
    max-height: 100vh;
}